import React, { useState } from "react"
import MainLayout from "../components/layout/mainLayout"

const Contact = props => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        fullName: name,
        emailAddress: email,
        messageBody: message,
      }),
    })
      .then(() => alert("Success!"))
      .catch(error => alert(error))

    e.preventDefault()
  }

  return (
    <>
      <MainLayout>
        <main className="Contact">
          <div className="Contact-imageWrapper">
            <section className="Contact-image" />
            <small>
              Adrienne’s production of an all-female Romeo and Juliet with
              femina shakes at Boston University
            </small>
          </div>
          <div className="Contact-form">
            <h2 className="Contact-form-title">Contact me</h2>
            <form
              name="contact"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              className="Contact-form-formWrapper"
              onSubmit={handleSubmit}
            >
              <input type="hidden" name="form-name" value="contact" />
              <p hidden>
                <label>
                  Don’t fill this out:{" "}
                  <input name="bot-field" onChange={() => {}} />
                </label>
              </p>
              <input
                type="text"
                name="fullName"
                className="Contact-form-nameInput"
                placeholder="Full Name"
                value={name}
                onChange={e => setName(e.target.value)}
              />
              <input
                type="email"
                name="emailAddress"
                className="Contact-form-emailInput"
                placeholder="Email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              <textarea
                className="Contact-form-textArea"
                name="messageBody"
                cols="30"
                rows="10"
                placeholder="What can I do for you?"
                value={message}
                onChange={e => setMessage(e.target.value)}
              ></textarea>
              <button className="Contact-form-btn" type="submit">
                Submit
              </button>
            </form>
          </div>
        </main>
      </MainLayout>
    </>
  )
}

export default Contact
